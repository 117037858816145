import { useLogin } from 'api-hooks/auth/mutation';
import classNames from 'classnames';
import formSetErrors from 'common/helpers/form-setError';
import notification from 'common/helpers/notification';
import colors from 'common/styles/colors';
import { Input } from 'components/elements/field';
import Form from 'components/elements/form';
import Text from 'components/elements/text';
import { setupBeforeHooks, useKY } from 'hooks/use-ky';
import useYupValidationResolver from 'hooks/use-yup-validation-resolver';
import { authDispatcher } from 'models/auth';
import { moduleStyles } from 'modules/styles.css';
import Head from 'next/head';
import Image from 'next/image';
import useTranslation from 'next-translate/useTranslation';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';

import { loginStyle } from './styles.css';

type LoginFormType = {
  username?: string;
  password?: string;
  otp?: string;
};

export default function Login() {
  const { t } = useTranslation();
  const { mutateAsync } = useLogin();
  const dispatch = useDispatch();
  const { getPermission, getMe } = authDispatcher(dispatch);
  const { setCredential } = useKY();
  const resolver = useYupValidationResolver(
    Yup.object().shape({
      username: Yup.string().required(),
      password: Yup.string().required(),
      otp: Yup.string()
        .nullable()
        .matches(/^[0-9 ]*$/, t('common:number_only')),
    }),
  );

  const formMethod = useForm<LoginFormType>({
    resolver,
    mode: 'onChange',
    defaultValues: {
      username: '',
      password: '',
      otp: '',
    },
  });

  const onSubmit = React.useCallback(
    async (values) => {
      try {
        const result = await mutateAsync({
          ...values,
        });

        await setupBeforeHooks(result?.data?.accessToken);
        setCredential(result.data);

        await localStorage.setItem(
          'auth.session-token',
          JSON.stringify(result.data),
        );

        await getMe();
        await getPermission();

        global.refreshToken = result?.data?.refreshToken;
        window.location.href = '/';
      } catch (e: any) {
        if (e.errors) {
          formSetErrors(e.errors, formMethod.setError);
        }
        notification.error({ message: e?.message });
      }
    },
    [formMethod.setError, getMe, getPermission, mutateAsync, setCredential],
  );

  return (
    <div className={classNames(loginStyle.container, 'row')}>
      <style global jsx>{`
        #__next {
          background: ${colors.productLight};
        }
      `}</style>
      <Head>
        <title>{t('common:title_login')}</title>
      </Head>
      <div className={classNames(loginStyle.innerContainer, 'row')}>
        <div className={loginStyle.leftContainer}>
          <div className={loginStyle.logoWrapper}>
            <Image
              src="/assets/logo-vertical.svg"
              fill
              alt="logo"
              className={moduleStyles.viewScreenLarge}
            />
            <Image
              src="/assets/logo-text-wt.svg"
              fill
              alt="logo"
              className={moduleStyles.viewScreenSmall}
            />
          </div>
        </div>
        <div className={classNames(loginStyle.contentContainer)}>
          <div className={loginStyle.card}>
            <Form methods={formMethod} onSubmit={onSubmit}>
              <div className={loginStyle.logoContainer}>
                <Text textVariant="HeadingMedium">{t('common:login')}</Text>
              </div>
              <div className={loginStyle.contentBody}>
                <div>
                  <Input
                    label={t('common:email')}
                    placeholder={t('common:enter_extra', {
                      extra: t('common:email'),
                    })}
                    name="username"
                    type="text"
                    required
                  />
                  <Input
                    label={t('common:password')}
                    placeholder={t('common:password')}
                    name="password"
                    type="password"
                    required
                  />
                </div>
              </div>
              <div>
                <div className={loginStyle.actionContainer}>
                  <Input
                    text={t('common:login')}
                    hideIcon
                    type="submit"
                    className={loginStyle.submitButton}
                  />
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
}
